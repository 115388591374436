import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../../Core";
import image1 from './../../../assets/image/rebrand/png/days-30.png';
import image2 from './../../../assets/image/rebrand/png/price-rises.png';
import image3 from './../../../assets/image/rebrand/png/existing-contract.png';
import image4 from './../../../assets/image/rebrand/png/price-match.png';
import image5 from './../../../assets/image/rebrand/png/minimum-speed.png';
import image6 from './../../../assets/image/rebrand/png/customer-service.png';
import image8 from './../../../assets/image/rebrand/png/youfibre_o.png'

import TextFit from "../TextFit/TextFit";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const ChooseYou = (props) => {

    const responsive = {
        0: { items: 2 },
        568: { items: 3 },
        1024: { items: 6 },
    };

    const items = [
        <>
            <img src={image1} className="mb-3" width={90}/>
            <h4>30 day<br/>MONEY BACK<br/>GUARANTEE</h4>
        </>,
        <>
            <img src={image2} className="mb-3" width={90}/>
            <h4>NO<br/>in-contract<br/>price rises</h4>
        </>,
        <>
            <img src={image3} className="mb-3" width={90}/>
            <h4>EXISTING<br/>CONTRACT<br/>BUY-OUT</h4>
        </>,
        <>
            <img src={image4} className="mb-3" width={90}/>
            <h4>PRICE<br/>MATCH<br/>COMMITMENT</h4>
        </>,
        <>
            <img src={image5} className="mb-3" width={90}/>
            <h4>Minimum<br/>speed<br/>guarantee</h4>
        </>,
        <>
            <img src={image6} className="mb-3" width={90}/>
            <h4>24/7<br/>Customer<br/>Service</h4>
        </>
      ];

    return (
        <Section className={'choose-you-bg dark-bg ' + (props.secondBackground && 'secondBackground')}>
            <Container>
                <Row className="mb-5">
                    <Col sm={12}>
                        <h2>WE'VE GOT Y<img src={image8} className="you"/>U.<br />IT'S OUR PROMISE.</h2>
                    </Col>
                </Row>
                <Row>
                    <AliceCarousel
                        mouseTracking
                        responsive={responsive}
                        disableButtonsControls={true}
                        infinite={true}
                        items={items}
                        controlsStrategy="alternate" />
                </Row>
            </Container>
        </Section>
    )
};

export default ChooseYou;
